:root {
  /* 
    --bussiness-template-1-hexagon-width-base and 
    --bussiness-template-1-hexagon-height-base must be the same ratio
    example : if change --bussiness-template-1-hexagon-width-base = 22.03em / 2 
    => --bussiness-template-1-hexagon-height-base = 19.081em / 2
  */
  --bussiness-template-1-hexagon-width-base: 22.03em;
  --bussiness-template-1-hexagon-height-base: 19.081em;

  --bussiness-template-1-primary-color: #FF7F00;

  --bussiness-template-2-primary-color: #009EDF;
  --bussiness-template-3-primary-color: #009EDF;

  --bussiness-template-2-contrast-text-color: #000;
  --bussiness-template-2-primary-simple-color: #F9A825;
  --primary-color: #eeaf1f;
}