$max-width: 95%;

.template3-persional {
    // background-color: white;
    color: black;
    display: grid;
    text-align: center;
    max-width: 100%;
    width: 100vw;
    height: auto;
    font-size: 25px;
    font-family: "Rasa", serif;

    .header-template3-persional {
        text-align: "center";

        .content-header-name {
            font-weight: bold;
            font-size: 1em;
        }

        .bar-under-name {
            margin: 5px auto;
            width: 60%;
            height: 2px;
            background-color: white;
            margin-bottom: 5px;
        }

        .box-content-header-job {

            .content-header-job {
                font-size: 0.7em;
                color: black;
                text-transform: uppercase;
            }
        }

    }

    .contact {
        display: grid;
        max-width: 90%;
        margin: 0 auto;
        padding: 10px 10px;
        text-align: "center";
        font-size: 0.7em;
        font-weight: bold;

        .contact-item {
            margin-top: 10px;
            display: flex;
            color: black;

            .icon-container {
                margin-right: 20px;
                color: black
            }

            .content-container {
                margin: 0 auto;
            }

        }

        .contact-icon {
            img {
                width: 30px;
                height: 30px;
                margin: 5px;
            }
        }
    }

    .template3-persional-custom-item {

        // width: 100vw;
        .line-space {
            width: 80%;
            height: 2px;
            background-color: white;
            margin: 0 auto;
            font-size: 1.5rem;
        }

        .introduce {
            max-width: $max-width;
            margin: 0 auto;
        }

        .name-content-header {
            font-weight: bold;
            text-transform: uppercase;

            .bar-under-name {
                margin-left: 10px;
                width: 10%;
                height: 2px;
                background-color: #424242;
                margin-bottom: 5px;
            }
        }

        .sample-content-item {
            padding: 10px;
            text-align: left;
            font-size: 1.2rem;
        }

        .edutation {
            @extend .sample-content-item;
            @extend .name-content-header;

            .content-title {
                display: flex;
                justify-content: space-between;


                .content-title-year {
                    font-size: 0.9em;
                    color: white;
                    width: 100px;
                    padding: 1px 4px;
                    border-radius: 5px;
                    background-color: black;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    // margin-left: 10px;
                }

                .content-title-text {
                    font-size: 0.8em;
                    text-transform: uppercase;
                    font-weight: bold;
                }
            }

            .content-item {
                text-transform: none;
                font-weight: normal;
                font-size: 0.8em;
                margin-bottom: 8px;
                margin-left: 10px
            }
        }

        .expertise {
            @extend .sample-content-item;
            @extend .name-content-header;

            .expertise-box {
                display: flex;
                margin-left: 20px;

                .expertise-content {
                    display: grid;
                    font-size: 18px;

                }

                .expertise-bar {
                    display: grid;
                    margin-left: 20px;

                    .expertise-bar-item {

                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .item {
                            width: 300px;

                            @media screen and (max-width: 768px) {
                                width: 300px;
                            }

                            @media screen and (max-width: 360px) {
                                width: 180px;
                            }
                        }
                    }

                }
            }

        }

        .work-experience {
            @extend .sample-content-item;
            @extend .name-content-header;


            .title {
                text-transform: initial;
                font-size: 18px;

                .content-title {
                    display: flex;
                    margin-top: 8px;

                    .content-title-year {
                        font-size: 14px;
                        color: white;
                        width: fit-content;
                        padding: 1px 4px;
                        border-radius: 5px;
                        background-color: black;
                        display: flex;
                        justify-content: center;
                        align-items: center
                    }

                    .content-title-text {
                        font-size: 18px;
                        margin-left: 10px;

                    }
                }

                .content-item {
                    font-size: 18px;
                    margin-bottom: 8px;
                    margin-left: 2px
                }
            }
        }

        .interests {
            @extend .sample-content-item;
            @extend .name-content-header;

            .interest-content {
                display: flex;
                justify-content: space-around;

                // width: max-content;
                .image-interest {
                    width: 30px;
                    height: 30px
                }

                .interest-item {
                    display: grid;
                    font-size: 0.9rem;
                    text-transform: none;

                    .interest-icon {
                        display: flex;
                        justify-content: center;
                        align-items: center
                    }
                }

            }
        }
    }

}