$max-width: 90%;

.persional-template2 {
    .header-template2-personal {

        position: relative;
        display: grid;

        .todo {
            // max-width: $max-width;
            height: 65vh;
            position: relative;
            overflow-x: hidden;

            .circle {
                position: absolute;
                opacity: 0.9;
                top: 5%;
                height: 65vh;
                right: -10%;

                @media screen and (max-width: 725px) {
                    height: 65vh;
                    right: -17%;
                }

                @media screen and (max-width: 553px) {
                    height: 60vh;
                    right: -25%;
                }

                @media screen and (max-width: 420px) {
                    right: -30%;
                }

                @media screen and (max-width: 335px) {
                    right: -50%;
                }
            }

            .avatar-header {
                position: absolute;
                left: 50%;
                transform: translate(-50%, 20%)
            }

            .item-info-header {
                font-size: 15px;
                color: black;
                width: fit-content;
                padding: 4px 10px;
                border-radius: 10px;
                background-color: #81D4FA;
                margin-bottom: 10px;
            }

        }
    }


    .name-content-header-2 {
        font-weight: bold;
    }


    .sample-content-item {
        margin: 10px 30px;
        text-align: left;
        font-size: 20px;
    }

    .education {
        @extend .sample-content-item;

        .content-item {
            margin-bottom: 20px;
            font-size: 15px;

            .year {
                color: #33CCFF;
            }
        }
    }

    .skills {
        @extend .sample-content-item;

        .content-title {
            font-size: 15px;
            color: white;
            width: fit-content;
            padding: 1px 4px;
            border-radius: 10px;
            background-color: #33CCFF;
        }

        .content-text {
            font-size: 15px;
            display: inline-block;
            margin-right: 10px;
            margin-bottom: 15px;
        }
    }

    .languages {
        @extend .sample-content-item;

        .content-title {
            font-weight: bold;
            font-size: 15px;
            display: flex;

            .separator {
                margin: 0 5px;
                /* Điều chỉnh khoảng cách giữa các đầu của dấu "-" */
                font-weight: normal;
            }

            .text {
                font-weight: normal;
            }

        }
    }

    .work-experiences {
        @extend .sample-content-item;

        .content-item {
            position: relative;
            font-size: 15px;

            .circle {
                position: absolute;
                width: 19px;
                height: 19px;
                border: 5px solid #33CCFF;
                border-radius: 50%;
                background-color: transparent;
                top: 0;
                transform: translateX(-15%);
            }

            .bar {
                position: absolute;
                width: 3px;
                height: 105%;
                background-color: #33CCFF;
                top: 17px;
                /* Khoảng cách từ đầu đến thanh */
                transform: translateX(150%);
                margin: 0;
            }

            .year {
                color: #33CCFF;
                margin-top: 20px;
                margin-left: 30px;
            }

            .text {
                margin-left: 30px;
            }
        }
    }

    .hobbies {
        @extend .sample-content-item;

        .content-title {
            display: flex;
            font-size: 15px;

            .image img {
                width: 25px;
                height: 20px;
                object-fit: cover;
            }

            .text {
                margin-left: 0.5rem;
                font-weight: normal;
            }
        }
    }
}