.r-hex.avatar {
  width: calc(var(--bussiness-template-1-hexagon-width-base) / 1.6);
  height: calc(var(--bussiness-template-1-hexagon-height-base) / 1.6);
}

.r-hex .r-hex-inner:before {
  display: none;
}

.r-hex.avatar .r-hex-inner:before {
  background: #000;
}

.r-hex-inner-2.avatar:before {
  margin-top: -1.5em;
  height: calc(var(--bussiness-template-1-hexagon-width-base) / 1.6);
  transform: skewX(-30deg) rotate(-90deg);
  background: url(https://izisolution.vn/upload/2021/he-thong-bi-la-gi3.jpg);
  background-size: cover;
  line-height: calc(var(--bussiness-template-1-hexagon-width-base) / 1.6);
  text-align: center;
  content: '';
}