.r-hex {
  overflow: hidden;
  display: inline-block;
  width: var(--bussiness-template-1-hexagon-width-base);
  height: var(--bussiness-template-1-hexagon-height-base);
  transform: rotate(-30deg) skewX(30deg);
  border-radius: 1em;
}

.r-hex *,
.r-hex *:before {
  display: block;
  overflow: hidden;
  width: inherit;
  height: inherit;
  border-radius: inherit;
}

.r-hex-inner {
  transform: skewX(-30deg) rotate(60deg) skewX(30deg);
}

.r-hex .r-hex-inner:before,
.r-hex-inner-2 {
  transform: skewX(-30deg) rotate(60deg) skewX(30deg);
  background: var(--primary-color);
  content: '';
}