.personal-template4 {
  font-family: 'Rasa', serif;
  $primary-color: #000;
  
  overflow-x: hidden;
  width: 100vw;

  .text {
    color: $primary-color;

    &.title {
      font-size: 1.5rem;
      font-weight: bold;
    }

    &.sub-title {
      font-size: 1.05rem;
      font-weight: bold;

      .small {
        font-size: 1rem;
      }
    }

    &.content {
      font-size: 1rem;
    }
  }


  .section-container {
    position: relative;
    z-index: 5;
    width: 100vw;
    min-height: 100vh;
    padding: 24px;
    margin-top: -1px;
    background: #C4C4C4;
  }

  .section {
    padding: 1rem 0
  }

  .divider {
    height: 2px;
    width: 1rem;
    margin: 0.8rem 0
  }
}