// var(--bussiness-template-2-primary-color): #009EDF;
$secondary-color: #28282C;
$avatar-width: 74vw;
$avatar-height: 40vh;
$quadrangle-title-left-position: -32%;
$quadrangle-title-top-position : 35%;

asd .business-template2 {
    .section {
        .hightlight-title {
            background-clip: text;
            -webkit-background-clip: text;
            color: transparent;
            background-image: var(--bussiness-template-2-primary-simple-color);
            background-color: var(--bussiness-template-2-primary-simple-color);
        }
    }

    .cover {
        width: 100vw;
        height: 100vh;
        background-color: #fff;
        position: relative;
        overflow: hidden;
    }

    .quadrangle {
        width: 100%;
        height: 40vh;
        transform: skewX(345deg);
        border-radius: 20px;
        position: absolute;

        &.title {
            background: linear-gradient(180deg, #0277BD 2.28%, #039BE5 21.79%, #4FC3F7 35.78%, #00B0FF 45.11%, #0288D1 54.44%, #01579B 70.13%, #01579B 83.7%);
            top: $quadrangle-title-top-position;
            left: $quadrangle-title-left-position;
            z-index: 100;
            opacity: 0.8;

            .title-border {
                top: -18%;
                left: 30%;
                background: transparent;
                border: 4px solid var(--bussiness-template-2-primary-color);
                width: $avatar-width;
                height: $avatar-height;
                transform: skewX(360deg);
            }
        }

        &.avatar {
            width: $avatar-width;
            height: $avatar-height;
            top: 20%;
            left: 13%;
            z-index: 10;
            // background-image: url(https://wallpapers.com/images/hd/business-background-83br7zd1i2i2o59x.jpg);
            background-size: cover;
            background-repeat: no-repeat;

            &--after {
                background: var(--bussiness-template-2-primary-color);
                // background-image: none;
                width: calc($avatar-width / 2);
                height: calc($avatar-height / 2);
                top: 18%;
                left: 60%;
                z-index: 5;
            }
        }

        &--extra {
            $height: calc($avatar-height / 3);
            width: 2rem;
            height: $height;
            background-color: $secondary-color;
            top: calc($quadrangle-title-top-position + calc($avatar-height - $height));
            left: calc($quadrangle-title-left-position * -2.4);
            z-index: 100;
            border-radius: 8px;
        }
    }

    .company-name-container {
        width: calc(100% + $quadrangle-title-left-position);
        height: $avatar-height;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        position: absolute;
        z-index: 100;
        top: $quadrangle-title-top-position;
        margin-left: 1rem;

        span {
            color: white;
            line-height: 1.2;
        }

        .corporate {
            font-size: 1.6rem;
        }

        .company-name {
            font-weight: bold;
            font-size: 2rem;
        }
    }

    .contact-container {
        $quadrangle-margin-left: -56px;

        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: fit-content;
        padding: 1rem;
        bottom: 1rem;
        top: calc($quadrangle-title-top-position + 40vh + 1em);
        width: 100vw;

        .quadrangle {
            background-color: $secondary-color;
            height: 100%;
            z-index: 10;
            transform: skewX(334deg);
            border-radius: 4px;
            left: $quadrangle-margin-left;
        }

        .contact {
            margin-left: $quadrangle-margin-left;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;
        }
    }

    .timeline {
        $line-height: 2rem;

        .container {
            width: fit-content;
            height: $line-height;
            position: relative;
            display: flex;
        }

        .quadrangle {
            height: $line-height;
            width: $line-height;
            position: absolute;
            z-index: 10;
            transform: skewX(340deg);
            border-radius: 2px;
            background: var(--bussiness-template-2-primary-color);
        }

        .time {
            font-size: 1.6rem;
            line-height: $line-height;
            z-index: 11;
            margin-left: 0.4rem;
        }

        .underline {
            width: 100%;
            border-bottom: 1px solid #000;
            position: absolute;
            bottom: 0;
            left: 60%;
        }
    }

    .player-wrapper {
        position: relative;
        padding-top: 56.25%;
        /* Player ratio: 100 / (1280 / 720) */
    }

    .react-player {
        position: absolute;
        top: 0;
        left: 0;
    }
}