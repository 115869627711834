$max-width: 90%;

.persional-template1 {
    background: linear-gradient(272deg, #DEB547 3.49%, #B6902C 29.67%, #F6CE63 59.57%, #A3832D 100%);
    font-family: "Rasa", serif;
    color: black;
    text-align: center;
    max-width: 100%;
    height: auto;
    font-size: 25px;
    overflow-y: hidden;

    .templete2 {

        .content-header-name {
            margin-top: 10px;
            font-weight: bold;
            font-size: 1em;
        }

        .box-content-header-job {
            text-align: "center";

            .content-header-job {
                display: inline-block;
                font-size: 0.6em;
                color: black;
                width: fit-content;
                padding: 2px 12px;
                border-radius: 10px;
                background-color: yellow;
            }
        }

    }

    .contact {
        display: grid;
        max-width: 90%;

        margin: 10px auto 0;
        font-size: 0.7em;

        .contact-item {
            padding: 10px;
            display: flex;
            color: black;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            justify-content: space-between;

            .icon-container {
                margin-right: 20px;
                text-wrap: nowrap;
            }

            .content-container {
                margin: 0 auto;
            }

        }

        .contact-icon {
            margin-top: 10px;


            img {
                width: 30px;
                height: 30px;
                margin: 5px;
            }
        }
    }

    .introduce {
        max-width: $max-width;
        margin: 0 auto;
        text-align: justify;
        font-size: 1.2rem;
    }

    .name-content-header {
        font-weight: bold;
        text-transform: uppercase;

        .bar-under-name {
            width: 8%;
            height: 5px;
            background-color: black;
            margin-bottom: 5px;
        }
    }

    .sample-content-item {
        margin: 10px 10px;
        text-align: left;
        font-size: 0.8em;
    }

    .highlight {
        @extend .sample-content-item;
        @extend .name-content-header;
        margin-top: 20px;

        .content-item {
            margin-left: 10px;
            font-size: 1rem;
            text-transform: capitalize;
            margin-bottom: 10px;
        }
    }


    .edutation {
        @extend .sample-content-item;
        @extend .name-content-header;

        .text-header-item {
            text-transform: uppercase;
            font-size: 0.9em;
        }

        .content-item {
            margin-left: 10px;
            font-size: 0.8em;
            text-transform: capitalize;
            margin-bottom: 20px;
        }
    }

    .expertise {
        @extend .sample-content-item;
        @extend .name-content-header;

        .expertise-box {
            display: flex;


            .expertise-content {
                display: grid;
                font-size: 1rem;
                text-transform: none;


            }

            .expertise-bar {
                display: grid;
                margin-left: 20px;
                width: 100%;

                .expertise-bar-item {

                    display: flex;
                    justify-content: center;
                    align-items: center;

                }

            }
        }

    }

    .work-experience {
        @extend .sample-content-item;
        @extend .name-content-header;

        .title {
            text-transform: initial;
            font-size: 1.1rem;

            .content-title {
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: justify;
                justify-content: space-between;

                .content-title-year {
                    font-size: 1rem;
                    color: white;
                    width: fit-content;
                    height: fit-content;
                    padding: 1px 4px;
                    border-radius: 4px;
                    background: #000;
                    text-wrap: nowrap;
                    justify-content: center;
                    align-items: center
                }

                .content-title-text {
                    font-size: 1rem;
                    margin-left: 10px;

                }
            }

            .content-item {
                font-size: 13px;
                margin-bottom: 8px;

            }
        }
    }

    .interests {
        @extend .sample-content-item;
        @extend .name-content-header;

        .interest-content {
            display: flex;
            justify-content: space-around;
            // width: max-content;

            .interest-item {
                display: grid;
                font-size: 0.7em;
                text-transform: none;

                .interest-icon {
                    display: flex;
                    justify-content: center;
                    align-items: center
                }
            }

        }
    }
}